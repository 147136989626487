
import { defineComponent, PropType } from 'vue';
import { ReportMetadataDTO, RBRecordDTO } from '@/api/dto/report';
import { reportProps } from '@/helpers/report-comp-props';

export default defineComponent({
  props: {
    ...reportProps,
  },
});
